import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { isNil } from 'lodash-es';
import {
  catchError,
  debounceTime,
  defer,
  distinctUntilChanged,
  exhaustMap,
  forkJoin,
  map,
  merge,
  Observable,
  of,
  pairwise,
  pipe,
  Subject,
  Subscription,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';

import { GenerateAiFlashcardsService } from '@stsm/ai-generation/services/generate-ai-flashcards.service';
import type { FlashcardGenerationCompletionData } from '@stsm/ai-generation/types/flashcard-generation';
import { FlashcardDataLoadingInfo, FlashcardDataStoreInterface } from '@stsm/flashcards/services/flashcard-data-store.interface';
import {
  FlashcardDeletedMessage,
  FlashcardMessageService,
  FlashcardsDeletedMessage,
  FlashcardsMovedMessage,
  FlashcardStateUpdatedMessage,
  FlashcardTagsUpdatedMessage,
  FlashcardUnlinkedMessage,
  FlashcardUpdatedMessage,
} from '@stsm/flashcards/services/flashcard-message.service';
import { currentFlashcardInSequenceIsAd, FlashcardsAdService } from '@stsm/flashcards/services/flashcards-ad.service';
import { FlashcardsStoreFacade } from '@stsm/flashcards/store';
import { Flashcard, isMultipleChoiceFlashcard } from '@stsm/flashcards/types/flashcard';
import { FlashcardsChunk } from '@stsm/flashcards/types/flashcard-chunk';
import { FlashcardFilterSettings } from '@stsm/flashcards/types/flashcard-filter-settings';
import { FlashcardLocation } from '@stsm/flashcards/types/flashcard-location';
import { FlashcardOrderMode, isChronologicalOrderMode } from '@stsm/flashcards/types/flashcard-order-mode';
import { FlashcardSelectionInfo } from '@stsm/flashcards/types/flashcard-selection-info';
import { getFullTextFromFlashcard } from '@stsm/flashcards/types/utils/get-full-text-from-flashcard';
import {
  areFlashcardFilterSettingsEqual,
  doesSearchStringFilterMatch,
  doesTagFilterMatch,
  getDefaultFlashcardFilterSettings,
} from '@stsm/flashcards/util/flashcard-filter-settings-util';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { ComponentStoreDevToolsService, LinkedComponentStore } from '@stsm/shared/services/component-store-dev-tools.service';
import { Id } from '@stsm/shared/types/id';
import { replaceAllOccurrences } from '@stsm/shared/util/array-util';
import { filterUndefined, tapWithLatestFrom, VOID } from '@stsm/shared/util/rxjs.util';
import { Studyset } from '@stsm/studysets/models/studyset';
import { StudysetsStoreFacade } from '@stsm/studysets/store/studysets-store-facade.service';
import { TagContentFilter } from '@stsm/tags/models/tag';

import { StudysetsService } from '../shared/services/studysets.service';
import { TagsService } from '../shared/services/tags.service';

import { FlashcardsService } from './flashcards.service';

const LOAD_NEXT_FLASHCARDS_THRESHOLD: number = 3;

interface FlashcardDataState {
  studysetId: number | undefined;
  studyset: Studyset | undefined;
  location: FlashcardLocation;
  flashcardIds: number[] | undefined;
  filteredCount: number;
  currentIndex: number | undefined;
  previousIndex: number | undefined;
  nextIndex: number | undefined;
  currentFlashcardId: number | undefined;
  filterSettings: FlashcardFilterSettings;
  loop: boolean;
  // only used for chronological order modes
  nextCursor: string;
  error: Error | undefined;
  initialFlashcardId: number | undefined;
  flashcardIdToBeRemovedWhenNextCardIsShown: number | undefined;
  isSwapButtonDisabled: boolean;
}

// For some reason, the DEFAULT_FLASHCARDS_FILTER is undefined when using a plain const for the initialState,
// in case the FlashcardDataStore is provided globally
const initialState: () => FlashcardDataState = () => ({
  studysetId: undefined,
  studyset: undefined,
  location: FlashcardLocation.STUDYSET,
  flashcardIds: undefined,
  filteredCount: 0,
  currentIndex: undefined,
  previousIndex: undefined,
  nextIndex: undefined,
  currentFlashcardId: undefined,
  filterSettings: getDefaultFlashcardFilterSettings(FlashcardLocation.LIST),
  loop: false,
  nextCursor: '',
  error: undefined,
  initialFlashcardId: undefined,
  flashcardIdToBeRemovedWhenNextCardIsShown: undefined,
  isSwapButtonDisabled: false,
});

export interface HasFlashcardNeighborsInfo {
  hasPrevious: boolean;
  hasNext: boolean;
}

interface FetchMoreTrigger {
  currentIndex: number;
  flashcardIds: number[];
  hasMoreFlashcards: boolean;
}

/**
 * Enable console warns in this file
 */
const DEBUG: boolean = false;

@Injectable({ providedIn: 'root' })
export class FlashcardDataStore extends ComponentStore<FlashcardDataState> implements FlashcardDataStoreInterface {
  flashcardIds$: Observable<number[]> = this.select((state: FlashcardDataState) => state.flashcardIds).pipe(filterUndefined());

  hasFlashcardNeighborsInfo$: Observable<HasFlashcardNeighborsInfo> = this.state$.pipe(
    map(({ previousIndex, nextIndex }: FlashcardDataState) => {
      return {
        hasPrevious: previousIndex !== undefined,
        hasNext: nextIndex !== undefined,
      };
    }),
  );

  filterSettings$: Observable<FlashcardFilterSettings> = this.select((state: FlashcardDataState) => state.filterSettings);

  location$: Observable<FlashcardLocation> = this.select((state: FlashcardDataState) => state.location);
  studysetId$: Observable<number | undefined> = this.select((state: FlashcardDataState) => state.studysetId);
  studyset$: Observable<Studyset | undefined> = this.select((state: FlashcardDataState) => state.studyset);

  hasError$: Observable<boolean> = this.select((state: FlashcardDataState) => !isNil(state.error));

  loadingInfo$: Observable<FlashcardDataLoadingInfo> = this.select(
    this.studysetId$,
    this.studyset$,
    this.select((state: FlashcardDataState) => state.error),
    this.select((state: FlashcardDataState) => state.flashcardIds), // no filterUndefined here
    (studysetId: number | undefined, studyset: Studyset | undefined, error: Error | undefined, flashcardIds: number[] | undefined) => {
      const isLoading = !isNil(studysetId) && isNil(error) && (studysetId !== studyset?.id || isNil(flashcardIds));
      const isLoaded = !isNil(studysetId) && studysetId === studyset?.id && !isNil(flashcardIds);

      return {
        isLoading,
        isLoaded,
        error,
      };
    },
    { debounce: true },
  );

  isLoading$: Observable<boolean> = this.loadingInfo$.pipe(map((loadingInfo: FlashcardDataLoadingInfo) => loadingInfo.isLoading));

  filteredFlashcardsCount$: Observable<number> = this.select((state: FlashcardDataState) => state.filteredCount);

  hasMoreFlashcards$: Observable<boolean> = this.select((state: FlashcardDataState) => !!state.nextCursor);
  currentIndex$: Observable<number | undefined> = this.select((state: FlashcardDataState) => state.currentIndex);
  currentFlashcardId$: Observable<number | undefined> = this.select((state: FlashcardDataState) => state.currentFlashcardId);

  isSwapButtonDisabled$: Observable<boolean> = this.select((state: FlashcardDataState) => state.isSwapButtonDisabled);

  readonly onFlashcardCreated: (flashcard: Flashcard) => Subscription = this.updater(
    (state: FlashcardDataState, newFlashcard: Flashcard) => {
      const { currentIndex, flashcardIds, filteredCount, location, filterSettings } = state;

      const safeFlashcardIds = flashcardIds ?? [];
      const updatedFlashcardIds = [...safeFlashcardIds];

      if (location === FlashcardLocation.LIST) {
        // Insert new flashcardId at the start or the end depending on the current filter
        if (filterSettings.orderMode === FlashcardOrderMode.OLDEST_FIRST) {
          updatedFlashcardIds.push(newFlashcard.id);
        } else {
          updatedFlashcardIds.unshift(newFlashcard.id);
        }
      } else {
        // Insert flashcard at the current index
        const safeCurrentIndex = currentIndex ?? 0;

        updatedFlashcardIds.splice(safeCurrentIndex, 0, newFlashcard.id);
      }

      return { ...state, flashcardIds: updatedFlashcardIds, filteredCount: filteredCount + 1 };
    },
  );

  readonly setIsSwapButtonDisabled: (currentFlashcard: Flashcard) => Subscription = this.updater(
    (state: FlashcardDataState, currentFlashcard: Flashcard): FlashcardDataState => {
      this._loggerService.debug('currentFlashcard', currentFlashcard);

      return {
        ...state,
        isSwapButtonDisabled: isMultipleChoiceFlashcard(currentFlashcard),
      };
    },
  );

  readonly setLocation: (location: FlashcardLocation) => Subscription = this.updater(
    (state: FlashcardDataState, location: FlashcardLocation) => ({
      ...state,
      location,
    }),
  );

  readonly setSearchString: (searchString: string) => Subscription = this.updater((state: FlashcardDataState, searchString: string) => ({
    ...state,
    filterSettings: {
      ...state.filterSettings,
      searchString,
    },
  }));

  readonly updateFilterSettings: (filterSettings: Partial<FlashcardFilterSettings>) => Subscription = this.updater(
    (state: FlashcardDataState, filterSettings: Partial<FlashcardFilterSettings>) => ({
      ...state,
      filterSettings: {
        ...state.filterSettings,
        ...filterSettings,
      },
    }),
  );

  readonly resetFilter: (location: FlashcardLocation) => Subscription = this.updater(
    (state: FlashcardDataState, location: FlashcardLocation) => ({
      ...state,
      filterSettings: getDefaultFlashcardFilterSettings(location),
    }),
  );

  readonly navigateToNeighbor: (direction: 'next' | 'previous') => Subscription = this.updater(
    (state: FlashcardDataState, direction: 'next' | 'previous' = 'next') => {
      // Check for Ad
      if (direction === 'next') {
        const adSequence = this._flashcardsAdService.isTimeForNextAd();

        if (currentFlashcardInSequenceIsAd(adSequence)) {
          DEBUG && this._loggerService.warn('SHOW AD');

          return {
            ...state,
            currentFlashcardId: this._flashcardsAdService.getAdFlashcardIdForSequence(adSequence, 'current'),
          };
        }
      }

      const { flashcardIds, nextIndex, previousIndex, flashcardIdToBeRemovedWhenNextCardIsShown } = state;

      if (isNil(flashcardIds)) {
        return state;
      }

      let newCurrentIndex: number | undefined = direction === 'previous' ? previousIndex : nextIndex;
      DEBUG && this._loggerService.debug('newCurrentIndex:', newCurrentIndex);

      const newCurrentFlashcardId = !isNil(newCurrentIndex) ? flashcardIds[newCurrentIndex] : undefined;

      let updatedFlashcardIds: number[] | undefined;

      if (flashcardIdToBeRemovedWhenNextCardIsShown) {
        DEBUG && this._loggerService.warn('flashcardIdToBeRemovedWhenNextCardIsShown', flashcardIdToBeRemovedWhenNextCardIsShown);

        updatedFlashcardIds = flashcardIds.filter((flashcardId: number) => flashcardId !== flashcardIdToBeRemovedWhenNextCardIsShown);
        newCurrentIndex = !isNil(newCurrentFlashcardId) ? updatedFlashcardIds.indexOf(newCurrentFlashcardId) : -1;

        if (newCurrentIndex === -1) {
          newCurrentIndex = undefined;
        }

        DEBUG && this._loggerService.debug('updated newCurrentIndex:', newCurrentIndex);

        return {
          ...state,
          flashcardIds: updatedFlashcardIds,
          filteredCount: updatedFlashcardIds.length,
          currentFlashcardId: !isNil(newCurrentIndex) ? newCurrentFlashcardId : undefined,
          currentIndex: newCurrentIndex,
          flashcardIdToBeRemovedWhenNextCardIsShown: undefined,
        };
      }

      if (newCurrentFlashcardId) {
        return {
          ...state,
          currentFlashcardId: newCurrentFlashcardId,
          currentIndex: newCurrentIndex,
        };
      }

      DEBUG && this._loggerService.warn('NO CHANGE');

      // No change
      return state;
    },
  );

  private readonly _setFlashcardsChunkToState: (chunk: FlashcardsChunk) => Subscription = this.updater(
    (state: FlashcardDataState, flashcardsChunk: FlashcardsChunk) => {
      const flashcardIds =
        flashcardsChunk.flashcards.length > 0
          ? // studyset with flashcards or subsequent request to fetch more flashcards
            [...(state.flashcardIds ?? []), ...flashcardsChunk.flashcards.map((flashcard: Flashcard) => flashcard.id)]
          : isNil(state.flashcardIds)
            ? // studyset with 0 flashcards
              []
            : // subsequent request which yielded 0 flashcards (e.g. because all flashcards are filtered out)
              state.flashcardIds;

      return <FlashcardDataState>{
        ...state,
        flashcardIds,
        filteredCount: flashcardsChunk.totalCount,
        // only set the cursor for chronological order modes
        nextCursor: isChronologicalOrderMode(state.filterSettings.orderMode) ? flashcardsChunk.nextPageCursor : '',
        error: undefined,
      };
    },
  );

  private readonly _onFlashcardUnlinked: (message: FlashcardUnlinkedMessage) => Subscription = this.updater(
    (state: FlashcardDataState, { originalFlashcardId, newFlashcard }: FlashcardUnlinkedMessage) => {
      if (isNil(state.flashcardIds)) {
        return state;
      }

      const updatedFlashcardIds = replaceAllOccurrences({
        array: [...state.flashcardIds],
        toReplace: originalFlashcardId,
        replaceWith: newFlashcard.id,
      });

      return {
        ...state,
        flashcardIds: updatedFlashcardIds,
        ...(state.currentFlashcardId === originalFlashcardId ? { currentFlashcardId: newFlashcard.id } : {}),
      };
    },
  );

  private readonly _removeFlashcardsWithIds: (removedFlashcardIds: number[]) => Subscription = this.updater(
    (state: FlashcardDataState, removedFlashcardIds: number[]) => {
      const deletedFlashcardIdsSet = new Set(removedFlashcardIds);

      const updatedFlashcardIds = [...(state.flashcardIds ?? [])].filter((flashcardId: number) => !deletedFlashcardIdsSet.has(flashcardId));

      return {
        ...state,
        flashcardIds: updatedFlashcardIds,
        filteredCount: updatedFlashcardIds.length,
      };
    },
  );

  /**
   * Clears loaded flashcard chunks, additionally updates studyset from remote to sync up states:
   * NOTE: HAS SIDE EFFECTS
   */
  private readonly _reset: () => Subscription = this.updater((state: FlashcardDataState) => {
    DEBUG && this._loggerService.warn('RESET');

    if (!isNil(state.studysetId)) {
      this._flashcardsStoreFacade.clearFlashcards(state.studysetId);
    }

    return {
      ...state,
      flashcardIds: undefined,
      // only preserve order
      filterSettings: {
        ...getDefaultFlashcardFilterSettings(state.location),
        orderMode: state.filterSettings.orderMode,
        slidesetId: state.location === FlashcardLocation.DOCUMENT_SIDEBAR ? state.filterSettings.slidesetId : undefined,
      },
      filteredCount: 0,
      nextCursor: '',
      loop: false,
      currentIndex: undefined,
      previousIndex: undefined,
      nextIndex: undefined,
      currentFlashcardId: undefined,
    };
  });

  private readonly _forceReset$: Subject<void> = new Subject<void>();

  constructor(
    private readonly _studysetService: StudysetsService,
    private readonly _studysetsStoreFacade: StudysetsStoreFacade,
    private readonly _flashcardsService: FlashcardsService,
    private readonly _flashcardsStoreFacade: FlashcardsStoreFacade,
    private readonly _flashcardsAdService: FlashcardsAdService,
    private readonly _loggerService: LoggerService,
    private readonly _tagsService: TagsService,
    private readonly _flashcardMessageService: FlashcardMessageService,
    private readonly _generateAiFlashcardsService: GenerateAiFlashcardsService,
    private readonly _componentStoreDevToolsService: ComponentStoreDevToolsService,
  ) {
    super(initialState());

    this._flashcardsAdService.init({ shouldShowTwoAds: true });

    this._componentStoreDevToolsService.linkComponentStoreToGlobalState(this.state$, LinkedComponentStore.FLASHCARDS_STORE);

    DEBUG && this.state$.subscribe((state: FlashcardDataState) => this._loggerService.debug('state', state));

    // HANDLE STUDYSET
    this.effect<number | undefined>(
      pipe(
        filterUndefined(),
        distinctUntilChanged(),
        tap(() => this.patchState({ studyset: undefined })),
        switchMap((studysetId: number) =>
          // always fetch the studyset from the backend because the studysets from the search do not include all relevant user-related counts
          this._studysetService.fetchStudysetIfNecessary(studysetId).pipe(
            switchMap(() => this._studysetsStoreFacade.studysetById(studysetId)),
            tap((studyset: Studyset) => {
              this.patchState({ studyset });
            }),
            catchError((error: Error) => {
              console.warn(error);

              return VOID;
            }),
          ),
        ),
      ),
    )(this.studysetId$);

    // Fetch Tags
    this.effect<number | undefined>(
      pipe(
        filterUndefined(),
        distinctUntilChanged(),
        switchMap((studysetId: Id) =>
          this._tagsService.fetchTags(studysetId, TagContentFilter.FLASHCARDS).pipe(
            catchError((error: unknown) => {
              console.warn(error);

              return VOID;
            }),
          ),
        ),
      ),
    )(this.studysetId$);

    this.effect(
      pipe(
        switchMap(() => this.studysetId$.pipe(filterUndefined(), take(1))),
        switchMap((studysetId: Id) =>
          this._tagsService.fetchTags(studysetId, TagContentFilter.FLASHCARDS).pipe(
            catchError((error: unknown) => {
              console.warn(error);

              return VOID;
            }),
          ),
        ),
      ),
    )(this._forceReset$);

    this.effect(
      pipe(
        withLatestFrom(this.studysetId$, (_: void, studysetId: number | undefined) => studysetId),
        filterUndefined(),
        switchMap((studysetId: number) => this._studysetService.getStudyset(studysetId)),
      ),
    )(this._forceReset$);

    merge(
      this.studysetId$.pipe(
        distinctUntilChanged(),
        filterUndefined(),
        tap((studysetId: number) => {
          DEBUG && this._loggerService.warn('new StudysetId', studysetId);

          this._flashcardsAdService.reset();
        }),
      ),
      this._forceReset$,
    )
      .pipe(
        tap(() => this._reset()),
        switchMap(() => {
          return this.filterSettings$.pipe(
            debounceTime(0),
            tap((filterSettings: FlashcardFilterSettings) => DEBUG && this._loggerService.warn('received filter settings', filterSettings)),
            distinctUntilChanged(areFlashcardFilterSettingsEqual),
            tap(() => this.patchState({ flashcardIds: undefined, currentIndex: undefined, nextCursor: '' })),
            switchMap(() => this._getFlashcards()),
          );
        }),
      )
      .subscribe();

    // LOAD MORE FLASHCARDS IN PRACTICE MODE
    this.effect(
      pipe(
        withLatestFrom(this.state$, (_: FetchMoreTrigger, state: FlashcardDataState) => state),
        exhaustMap(({ currentIndex, flashcardIds, nextCursor, location, filterSettings }: FlashcardDataState) => {
          if (
            location === FlashcardLocation.LEARN &&
            (nextCursor.length > 0 || !isChronologicalOrderMode(filterSettings.orderMode)) &&
            !isNil(flashcardIds) &&
            flashcardIds.length >= 2 &&
            !isNil(currentIndex) &&
            currentIndex + LOAD_NEXT_FLASHCARDS_THRESHOLD >= flashcardIds?.length
          ) {
            DEBUG && this._loggerService.warn('LOAD MORE FLASHCARDS');

            return this.loadMoreFlashcards().pipe(
              catchError((error: Error) => {
                this._loggerService.error(error);

                this.patchState({ error });

                return VOID;
              }),
            );
          }

          return VOID;
        }),
      ),
    )(
      this.select(
        this.currentIndex$.pipe(filterUndefined()),
        this.flashcardIds$.pipe(filterUndefined()),
        this.hasMoreFlashcards$,
        (currentIndex: number, flashcardIds: number[], hasMoreFlashcards: boolean) => ({ currentIndex, flashcardIds, hasMoreFlashcards }),
        { debounce: true },
      ),
    );

    this.effect(
      pipe(
        tapWithLatestFrom(this.state$, (state: FlashcardDataState) => {
          this._calculateAndUpdateState(state);
        }),
      ),
    )(
      this.select(
        this.flashcardIds$,
        this.currentIndex$,
        (flashcardIds: number[], currentIndex: number | undefined) => ({ flashcardIds, currentIndex }),
        { debounce: true },
      ),
    );

    // Make sure that flashcard location and order are compatible
    this.effect(
      pipe(
        tap(([previousLocation, currentLocation]: [FlashcardLocation, FlashcardLocation]) =>
          this._resetFlashcardsAndFilterUponModeSwitch(previousLocation, currentLocation),
        ),
      ),
    )(this.location$.pipe(pairwise()));

    // Listen for flashcard updates that need to be applied to the local state
    this.effect(pipe(tap((message: FlashcardDeletedMessage) => this._removeFlashcardsWithIds([message.flashcardId]))))(
      this._flashcardMessageService.onFlashcardDeleted(),
    );

    this.effect(
      pipe(
        tap(({ selectionInfos }: FlashcardsDeletedMessage) => {
          const flashcardIds = selectionInfos.map((selectionInfo: FlashcardSelectionInfo) => selectionInfo.flashcardId);
          this._removeFlashcardsWithIds(flashcardIds);
        }),
      ),
    )(this._flashcardMessageService.onFlashcardsDeleted());

    this.effect<FlashcardsMovedMessage>(
      pipe(
        withLatestFrom(this.studysetId$),
        tap(([{ flashcardsMovedData }, studysetId]: [FlashcardsMovedMessage, number | undefined]) => {
          const { sourceStudysetId, destination, flashcardIds } = flashcardsMovedData;
          const isMoveToSubtopic = sourceStudysetId === destination.parentStudysetId;

          if (studysetId === sourceStudysetId && !isMoveToSubtopic) {
            this._removeFlashcardsWithIds(flashcardIds);
          }
        }),
      ),
    )(this._flashcardMessageService.onFlashcardsMoved());

    this.effect(pipe(tap((message: FlashcardUnlinkedMessage) => this._onFlashcardUnlinked(message))))(
      this._flashcardMessageService.onFlashcardUnlinked(),
    );

    this.effect<FlashcardTagsUpdatedMessage>(
      pipe(
        withLatestFrom(this.studysetId$, this.filterSettings$),
        tap(([message, studysetId, filterSettings]: [FlashcardTagsUpdatedMessage, number | undefined, FlashcardFilterSettings]) => {
          if (
            studysetId === message.studysetId &&
            !doesTagFilterMatch(filterSettings.filterTagIds, message.tagIds, message.communityAppliedTagIds)
          ) {
            console.debug('card does not match tag filter, remove from list');
            this.patchState({ flashcardIdToBeRemovedWhenNextCardIsShown: message.flashcardId });
            this.navigateToNeighbor('next');
          }
        }),
      ),
    )(this._flashcardMessageService.onFlashcardTagsUpdated());

    this.effect<FlashcardUpdatedMessage>(
      pipe(
        withLatestFrom(this.studysetId$, this.filterSettings$),
        tap(([message, studysetId, filterSettings]: [FlashcardUpdatedMessage, number | undefined, FlashcardFilterSettings]) => {
          if (
            studysetId === message.studysetId &&
            !doesSearchStringFilterMatch(filterSettings.searchString, getFullTextFromFlashcard(message.updatedFlashcard))
          ) {
            console.debug('card does not match search string, remove from list');
            this.patchState({ flashcardIdToBeRemovedWhenNextCardIsShown: message.updatedFlashcard.id });
            this.navigateToNeighbor('next');
          }
        }),
      ),
    )(this._flashcardMessageService.onFlashcardUpdated());

    this.effect<FlashcardStateUpdatedMessage>(
      pipe(
        withLatestFrom(this.studysetId$, this.filterSettings$),
        tap(([message, studysetId, filterSettings]: [FlashcardStateUpdatedMessage, number | undefined, FlashcardFilterSettings]) => {
          if (studysetId === message.studysetId && !filterSettings.feedbackStateFilter[message.newState]) {
            console.debug('card does not match state filter, remove from list');
            this.patchState({ flashcardIdToBeRemovedWhenNextCardIsShown: message.flashcardId });
          }
        }),
      ),
    )(this._flashcardMessageService.onFlashcardStateUpdated());

    this.effect<number | undefined>(
      pipe(
        filterUndefined(),
        switchMap((studysetId: number) =>
          this._generateAiFlashcardsService
            .onGenerateFlashcardsCompletion(studysetId)
            .pipe(
              switchMap((completionData: FlashcardGenerationCompletionData | undefined) =>
                !isNil(completionData) ? this.forceReload() : VOID,
              ),
            ),
        ),
      ),
    )(this.studysetId$);
  }

  fetchFlashcards({
    studysetId,
    location,
    initialFlashcardId,
    slidesetId,
  }: {
    studysetId: number;
    location: FlashcardLocation;
    initialFlashcardId?: number;
    slidesetId?: number;
  }): void {
    DEBUG && this._loggerService.info('FETCH FLASHCARDS', studysetId, location, initialFlashcardId);
    this.patchState((state: FlashcardDataState) => {
      return {
        studysetId,
        location,
        initialFlashcardId,
        filterSettings: {
          ...state.filterSettings,
          slidesetId,
        },
      };
    });
  }

  forceReload(): Observable<void> {
    DEBUG && this._loggerService.warn('FORCE REFRESH');

    this._forceReset$.next();

    return VOID;
  }

  loadMoreFlashcards(): Observable<void> {
    return this._getFlashcards();
  }

  private _calculateAndUpdateState({ currentIndex, loop, flashcardIds, filterSettings, nextCursor }: FlashcardDataState): void {
    // Calculate state update
    let previousIndex: number | undefined;
    let nextIndex: number | undefined;
    let newCurrentIndex = currentIndex ?? 0;
    let newLoop = loop;

    if (isNil(flashcardIds)) {
      this.patchState({
        previousIndex: undefined,
        nextIndex: undefined,
        loop: false,
      });

      return;
    }

    if (flashcardIds.length) {
      if (flashcardIds.length === 1) {
        previousIndex = undefined;
        nextIndex = undefined;
        newCurrentIndex = 0;
      }

      if (flashcardIds.length > 1) {
        previousIndex = flashcardIds[newCurrentIndex - 1] ? newCurrentIndex - 1 : undefined;
        nextIndex = flashcardIds[newCurrentIndex + 1] ? newCurrentIndex + 1 : undefined;

        if (
          newLoop ||
          ((isNil(nextIndex) || isNil(flashcardIds[nextIndex])) && isChronologicalOrderMode(filterSettings.orderMode) && !nextCursor)
        ) {
          newLoop = true;

          previousIndex = !isNil(previousIndex) && flashcardIds[previousIndex] ? previousIndex : flashcardIds.length - 1;
          nextIndex = !isNil(nextIndex) && flashcardIds[nextIndex] ? nextIndex : 0;
        }
      }
    }

    const stateUpdate = {
      currentIndex: newCurrentIndex, // only changes if the currentIndex was undefined before
      previousIndex,
      nextIndex,
      loop: newLoop,
      currentFlashcardId: flashcardIds[newCurrentIndex],
    };

    DEBUG && this._loggerService.warn('CALCULATE', stateUpdate);

    this.patchState({
      ...stateUpdate,
    });
  }

  private _getFlashcards(): Observable<void> {
    return this.state$.pipe(
      take(1),
      switchMap(({ nextCursor, initialFlashcardId, studysetId, filterSettings }: FlashcardDataState) => {
        if (isNil(studysetId)) {
          return VOID;
        }

        return forkJoin([
          this._flashcardsService.getFlashcardsChunk(studysetId, filterSettings, { pageCursor: nextCursor, quantity: 20 }),
          this._getFlashcardFromRouteIfAvailable(studysetId, initialFlashcardId),
        ]).pipe(
          tap(([flashcardsChunk, flashcardFromRoute]: [FlashcardsChunk, Flashcard | undefined]) => {
            DEBUG && this._loggerService.warn('received chunk', flashcardsChunk, 'flashcard from route', flashcardFromRoute);

            const routeFlashcardIndexInChunk = flashcardsChunk.flashcards.findIndex(
              (flashcard: Flashcard) => flashcard.id === flashcardFromRoute?.id,
            );

            const shouldPrependFlashcardFromRoute = !!flashcardFromRoute && routeFlashcardIndexInChunk === -1;

            const updatedChunk = {
              ...flashcardsChunk,
              flashcards: [...(shouldPrependFlashcardFromRoute ? [flashcardFromRoute] : []), ...flashcardsChunk.flashcards],
            };

            this._flashcardsStoreFacade.upsertFlashcards(studysetId, updatedChunk.flashcards);

            this._setFlashcardsChunkToState(updatedChunk);

            if (routeFlashcardIndexInChunk !== -1) {
              this.patchState({ currentIndex: routeFlashcardIndexInChunk });
            }
          }),
          switchMap(() => VOID),
          catchError((error: Error) => {
            this._loggerService.error('ERROR fetching flashcards', error);

            this.patchState({ error });

            return VOID;
          }),
        );
      }),
    );
  }

  /**
   * If the Trainer is opened with an initial flashcardId, this flashcard is prepended
   *
   * @param studysetId the studysetId
   * @param initialFlashcardId the initial flashcard id
   */
  private _getFlashcardFromRouteIfAvailable(studysetId: number, initialFlashcardId?: number): Observable<Flashcard | undefined> {
    return defer(() => {
      if (!initialFlashcardId || initialFlashcardId === -1) {
        // There is no flashcard in the route
        return of(undefined);
      }

      // In case the flashcardId from the route is not part of the chunk
      return this._flashcardsService.getFlashcardById(studysetId, initialFlashcardId).pipe(
        catchError((error: Error) => {
          DEBUG && this._loggerService.warn(error);

          return of(undefined);
        }),
      );
    }).pipe(tap(() => this.patchState({ initialFlashcardId: undefined })));
  }

  private _resetFlashcardsAndFilterUponModeSwitch(previousLocation: FlashcardLocation, currentLocation: FlashcardLocation): void {
    const locationDefaultSettings = getDefaultFlashcardFilterSettings(currentLocation);
    const filterSettings = this.state().filterSettings;
    const initialFlashcardId = this.state().initialFlashcardId;
    const flashcardIds = this.state().flashcardIds;

    if (currentLocation === FlashcardLocation.STUDYSET) {
      this.patchState({ filterSettings: locationDefaultSettings });

      return;
    }

    const newOrderMode =
      isChronologicalOrderMode(filterSettings.orderMode) && isChronologicalOrderMode(locationDefaultSettings.orderMode)
        ? filterSettings.orderMode
        : locationDefaultSettings.orderMode;

    DEBUG && this._loggerService.warn('current Order', filterSettings.orderMode, 'newOrderMode', newOrderMode);

    if (filterSettings.orderMode === newOrderMode) {
      this._handleEqualOrderMode({
        initialFlashcardId,
        currentLocation,
        previousLocation,
        flashcardIds,
      });

      return;
    }

    this.patchState({
      filterSettings: { ...filterSettings, orderMode: newOrderMode },
    });
  }

  private _handleEqualOrderMode({
    initialFlashcardId,
    currentLocation,
    previousLocation,
    flashcardIds,
  }: {
    initialFlashcardId: Id | undefined;
    currentLocation: FlashcardLocation;
    previousLocation: FlashcardLocation;
    flashcardIds: Id[] | undefined;
  }): void {
    DEBUG && this._loggerService.warn('ORDER STAYED THE SAME');

    // to ensure that the initial flashcard is shown, and removed from the state after a filter change
    if (currentLocation === FlashcardLocation.LEARN) {
      const initialFlashcardIndex = flashcardIds?.findIndex((flashcardId: number) => flashcardId === initialFlashcardId);

      if (initialFlashcardIndex !== -1) {
        this.patchState({ currentIndex: initialFlashcardIndex, initialFlashcardId: undefined });
      }
    }

    if (currentLocation === FlashcardLocation.LIST && previousLocation === FlashcardLocation.LEARN) {
      DEBUG && this._loggerService.warn('FROM LEARN TO LIST');
      this.patchState({ currentIndex: undefined, currentFlashcardId: undefined });
    }
  }
}
